<template>
	<div class="login">
				<div class="box-top"></div>
				<div class="box-center">
					<div class="box-logo">
						<img src="../assets/mask/login/logo.png" style="width: 119px; height: 118px" />
						<div class="logo-text">
              <div class="title">Mask刑事行政合规体检</div>	
              <div>为国内企业度身定制的法律服务产品</div>	
              <div>让企业发现风险、防控风险</div>	
						</div>
					</div>
					<div class="content">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="账号登录" name="Account">
                <el-form ref="formLogin" :model="formLogin" :rules="loginRules" label-width="0">
                  <el-form-item prop="username">
                    <el-input placeholder="请输入账号(关联的手机号)" v-model="formLogin.username" clearable>
                      <img slot="prefix" 
                        src="../assets/mask/login/zh.png" 
                        style="width: 22px; height: 22px;padding-top: 10px;" />
                    </el-input>
                  </el-form-item>

                  <el-form-item prop="password">
                    <el-input 
                      placeholder="请输入密码" 
                      v-model="formLogin.password" 
                      show-password
                    >
                      <img slot="prefix"
                        src="../assets/mask/login/mm.png" 
                        style="width: 22px; height: 22px;padding-top: 10px;" />
                    </el-input>
                  </el-form-item>
                  <div style="display: flex;justify-content: flex-end;">
                    <el-button type="text" style="padding: 0 20px" @click="handleRetrieve">忘记密码 > </el-button>
                  </div>
                  <div style="margin-top: 10px;">
                    <el-button 
                      type="warning" 
                      v-loading.fullscreen.lock="fullscreenLoading" 
                      @click="handleLogin('formLogin')"
                      >登录
                    </el-button>
                  </div>
                </el-form>
								<div>	
									<el-button type="primary" @click="handleRegister">注册成为VIP会员</el-button>
								</div>
							</el-tab-pane>
							
							<el-tab-pane label="短信登录" name="note">
                <el-form ref="smsLogin" :model="smsLogin" :rules="loginRules" label-width="0">
                  <el-form-item prop="phone">
                    <el-input placeholder="请输入手机号" v-model="smsLogin.phone" clearable>
                      <img slot="prefix" 
                        src="../assets/mask/login/sj.png" 
                        style="width: 22px; height: 22px;padding-top: 10px;" />
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="value" class="sms">
                    <el-input placeholder="请输入验证码" v-model="smsLogin.value">
                      <img slot="prefix"
                        src="../assets/mask/login/yzm.png" 
                        style="width: 22px; height: 22px;padding-top: 10px;" />
                      <el-button 
                        slot="suffix" 
                        type="primary" 
                        @click="sendSMS" 
                        :disabled="btnDisabled"
                        size="mini">
                        {{!btnDisabled ? '获取验证码' : countLoading + '秒后可重发'}}
                      </el-button>
                    </el-input>
                  </el-form-item>
                </el-form>

								<div>
									<el-button type="warning" @click="handleSmsLogin('smsLogin')">登录</el-button>
								</div>
								<div>	
									<el-button type="primary" @click="handleRegister">注册成为VIP会员</el-button>
								</div>
							</el-tab-pane>
						</el-tabs>
					</div>
					<div class="floor">
						Copyright ©2022-2025  Mask版权所有
					</div>
				</div>
			</div>
</template>

<script>
import { mapActions } from 'vuex'
import {smsSend, webLogin, webSmsLogin} from "../api";
import { isValidMobileNumber } from "../utils";
import store from "../../src/store/index";
	export default {
    name: 'login',
    data() {
			return {
        countLoading: 60,
        btnDisabled: false,
        fullscreenLoading: false,
        stores: store,
				activeName: 'Account',
        formLogin: {
          username: '',
          password: ''
        },
        smsLogin: {
          phone: '',
          value: '',
          id: '',
        },
        loginRules: {
          username: [
            {required: true, message: "请输入账号", trigger: "blur"}
          ],
          password: [
            {required: true, message: "请输入密码", trigger: "blur"}
          ],
          phone: [
            {required: true, message: "请输入手机号", trigger: "blur"}
          ],
          value: [
            {required: true, message: "请输入验证码", trigger: "blur"}
          ],
        },
			}
		},
		methods: {
      ...mapActions(['updateUserInfo']),
			handleClick(tab, event) {
				console.log(tab, event);
			},
			handleLogin(formLogin){
				//this.$router.push({path:'/criterion'});
        this.$refs[formLogin].validate((valid) => {
          if (valid) {
            this.fullscreenLoading = true;
            const { username, password } = this.formLogin;
            const encryption = this.$sha1(this.$md5(this.formLogin.password));
            webLogin({
              username,
              password: encryption,
            })
              .then((res)=> {
                this.updateUserInfo(res);
                this.fullscreenLoading = false;
                this.$message({
                    type: "success",
                    message: "登录成功"
                });
                this.$router.replace({
                  path: "/criterion",
                });
              })
              .finally(() => {
                  this.fullscreenLoading = false;
              });
          } else {
            return ;
          }
        });
			},
      carriage() {
        console.log(this.formLogin);
      },
			handleRetrieve(){
				this.$router.push({path:'/retrieve'});
			},
			handleRegister(){
				this.$router.push({path:'/register'});
			},
      sendSMS(){
        if (!isValidMobileNumber(this.smsLogin.phone)) {
          this.$message.error('请输入正确手机号');
          return;
        }
        this.btnDisabled = true;
        this.countLoading = 60;
        var time = setInterval(() => {
          this.countLoading--;
          if(this.countLoading <= 0){
            this.btnDisabled = false;
            clearInterval(time);
          }
        },1000);
        smsSend({phone: this.smsLogin.phone}).then(({ data, code }) => {
          if (code === 200) {
            this.smsLogin.id = data.id;
          }
        })
        .catch(() => {
          this.$message.error('发送短信失败');
        });
      },
      handleSmsLogin(smsLogin){
        this.$refs[smsLogin].validate((valid) => {
          if (valid) {
            this.fullscreenLoading = true;
            webSmsLogin(this.smsLogin)
              .then((res)=> {
                this.updateUserInfo(res);
                this.fullscreenLoading = false;
                this.$message({
                    type: "success",
                    message: "登录成功"
                });
                
                this.$router.replace({
                  path: "/criterion",
                });
              })
              .finally(() => {
                  this.fullscreenLoading = false;
              });
          } else {
            return ;
          }
        });
      }
		}
	}
</script>

<style lang="less">
	.login{
		.box-top{
			width: 100%;
			height: 440px;
			background: #444A59;
			position: absolute;
			z-index: -1;
		}
		.box-center{
			width: 430px;
			margin: 0 auto;
			
			.box-logo{
				padding-top: 99px;
				padding-bottom: 35px;
				text-align: center;
			}
			.title{
				font-size: 18px;
				line-height: 27px;
				color: #fff;
				margin-top: 10px;
			}
			.logo-text{
				font-size: 14px;
				line-height: 24px;
				color: #C8C8C8;
			}
		}
		.content{
			width: 332px;
			background-color: #fff;
			border-radius: 5px;
			padding: 15px 49px;
			
			.el-tabs__nav {
				float: none !important;
				text-align: center !important;
			}
			.el-tabs__nav-wrap::after{
				background-color: #fff;
			}
			.el-tabs__active-bar{
				left: 16px !important;
				width: 30px !important;
				height: 3px !important;
				margin-left: 85px;
				background-color: #DAB87A !important;
			}
			.el-input__inner:hover,
			.el-input__inner{
				border-radius: 0;
				border: 1px solid #fff;
				border-bottom: 1px solid #E6E6E6;
			}
			.el-input--prefix .el-input__inner {
				height: 45px;
				padding-left: 40px;
			}
			
			.el-button--warning:focus, 
			.el-button--warning:hover,
			.el-button--warning {
				background-color: #DAB87A;
				border-color: #DAB87A;
				width: 100%;
				margin-bottom: 25px;
				font-size: 18px;
			}
			.el-button--primary:focus,
			.el-button--primary:hover,
			.el-button--primary{
				background-color: #1577CB;
				border-color: #1577CB;
				width: 100%;
				margin-bottom: 25px;
				font-size: 18px;
			}
      .sms {
        .el-button--mini:focus,
        .el-button--mini:hover,
        .el-button--mini{
          background-color: #1577CB;
          border-color: #1577CB;
          width: 100%;
          font-size: 12px;
        }
      }
		}
		
		
		.floor{
			font-size: 14px;
			color: #C8C8C8;
			padding-left: 90px;
			position: absolute;
			bottom: 10px;
			z-index: -1;
		}
	}
</style>

